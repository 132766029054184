import React from 'react'
import { rem } from 'polished'

import { Svg } from '../../design-system'

export const IceCreamIcon = ({ width, height, fill, ...otherProps }) => (
  <Svg
    width={rem(width)}
    height={rem(height)}
    style={{ marginRight: rem(10) }}
    path={[
      'M7.50586 15L15.0059 28.125L22.5059 15H7.50586ZM22.9688 9.43359C23.1445 8.90625 23.2441 8.10352 23.2441 7.50586C23.2441 4.39453 20.7246 1.875 17.6133 1.875C15.7734 1.875 14.1445 2.75391 13.1133 4.11914C13.1133 4.11914 12.5801 4.93945 12.4805 5.81836L12.2813 5.75977C12.1465 5.23242 12.0645 4.58789 12.375 3.91406C11.9414 3.76758 11.8242 3.75 11.3438 3.75C8.90039 3.75 6.91406 5.73047 6.91406 8.17969C6.91406 8.54883 6.96094 9.08789 7.04883 9.43359C6.23438 9.63867 5.625 10.377 5.625 11.25C5.625 12.2812 6.46875 13.125 7.5 13.125H22.5C23.5313 13.125 24.375 12.2812 24.375 11.25C24.375 10.3828 23.7773 9.64453 22.9688 9.43359Z'
    ]}
    fill={fill}
    viewBox="0 0 30 30"
    {...otherProps}
  />
)

IceCreamIcon.defaultProps = {
  width: 20,
  height: 20,
  fill: '#6d7893c4'
}

export const CloudIcon = ({ width, height, fill, ...otherProps }) => (
  <Svg
    width={rem(width)}
    height={rem(height)}
    style={{ marginRight: rem(10) }}
    path={[
      'M23.6134 7.7149C22.7928 3.67199 19.2772 0.625 15 0.625C11.6017 0.625 8.67199 2.55848 7.20715 5.37115C3.69152 5.72277 0.9375 8.71082 0.9375 12.3438C0.9375 16.2107 4.1015 19.375 7.96875 19.375H23.2031C26.4258 19.375 29.0625 16.7383 29.0625 13.5156C29.0625 10.4103 26.6604 7.89057 23.6134 7.7149Z'
    ]}
    fill={fill}
    viewBox="0 0 30 20"
    {...otherProps}
  />
)

CloudIcon.defaultProps = {
  width: 20,
  height: 20,
  fill: '#6d7893c4'
}

export const FastIcon = ({ width, height, fill, ...otherProps }) => (
  <Svg
    width={rem(width)}
    height={rem(height)}
    style={{ marginRight: rem(10) }}
    path={[
      'M0.375 0.8125V13.9375H4.125V25.1875L11.625 10.1875H7.875L11.625 0.8125H0.375Z'
    ]}
    fill={fill}
    viewBox="0 0 12 26"
    {...otherProps}
  />
)

FastIcon.defaultProps = {
  width: 20,
  height: 20,
  fill: '#6d7893c4'
}

export const NotifyIcon = ({ width, height, fill, ...otherProps }) => (
  <Svg
    width={rem(width)}
    height={rem(height)}
    style={{ marginRight: rem(10) }}
    path={[
      'M11.0002 25.1875C12.3349 25.1875 13.4267 24.0907 13.4267 22.75H8.57376C8.57376 24.0907 9.66553 25.1875 11.0002 25.1875ZM18.8863 17.875V11.1719C18.8863 7.45469 16.278 4.28605 12.8201 3.49369V2.64062C12.8201 1.60457 12.0316 0.8125 11.0002 0.8125C9.96887 0.8125 9.18038 1.60457 9.18038 2.64062V3.49369C5.72247 4.28605 3.11417 7.45469 3.11417 11.1719V17.875L0.687744 20.3125V21.5312H21.3127V20.3125L18.8863 17.875Z'
    ]}
    fill={fill}
    viewBox="0 0 22 26"
    {...otherProps}
  />
)

NotifyIcon.defaultProps = {
  width: 20,
  height: 20,
  fill: '#6d7893c4'
}

export const SupportIcon = ({ width, height, fill, ...otherProps }) => (
  <Svg
    width={rem(width)}
    height={rem(height)}
    style={{ marginRight: rem(10) }}
    path={[
      'M22 16.375C20.4944 16.375 18.9978 16.1432 17.5627 15.6878C17.1251 15.5626 16.6252 15.6249 16.3128 16.0003L13.5625 18.7499C9.99984 16.9378 7.12512 14.0624 5.31234 10.4998L8.06267 7.75014C8.37509 7.43765 8.4999 6.93779 8.37509 6.50016C7.87488 5.06279 7.62498 3.5625 7.62498 1.99998C7.62498 1.31273 7.06254 0.75 6.375 0.75H1.99998C1.31244 0.75 0.75 1.31273 0.75 1.99998C0.75 13.7498 10.2502 23.25 22 23.25C22.6873 23.25 23.25 22.6873 23.25 22V17.625C23.25 16.9378 22.6873 16.375 22 16.375V16.375Z'
    ]}
    fill={fill}
    viewBox="0 0 24 24"
    {...otherProps}
  />
)

SupportIcon.defaultProps = {
  width: 20,
  height: 20,
  fill: '#6d7893c4'
}

export const TeamIcon = ({ width, height, fill, ...otherProps }) => (
  <Svg
    width={rem(width)}
    height={rem(height)}
    style={{ marginRight: rem(10) }}
    path={[
      'M18.7727 7.59375C20.7414 7.59375 22.3523 6.0116 22.3523 4.07812C22.3523 2.14471 20.7414 0.5625 18.7727 0.5625C16.804 0.5625 15.1932 2.14471 15.1932 4.07812C15.1932 6.0116 16.804 7.59375 18.7727 7.59375V7.59375ZM9.2273 7.59375C11.196 7.59375 12.8068 6.0116 12.8068 4.07812C12.8068 2.14471 11.196 0.5625 9.2273 0.5625C7.25861 0.5625 5.6477 2.14471 5.6477 4.07812C5.6477 6.0116 7.25861 7.59375 9.2273 7.59375ZM9.2273 10.1719C6.42312 10.1719 0.875 11.5194 0.875 14.2734V17.4375H17.75V14.2734C17.75 11.5194 12.0313 10.1719 9.2273 10.1719V10.1719ZM18.7727 10.8166C18.415 10.8166 18.1677 10.8166 17.75 10.875C19.1226 11.8712 19.625 12.5156 19.625 14.2734V17.4375H27.125V14.2734C27.125 11.5194 21.5769 10.8166 18.7727 10.8166Z'
    ]}
    fill={fill}
    viewBox="0 0 28 18"
    {...otherProps}
  />
)

TeamIcon.defaultProps = {
  width: 20,
  height: 20,
  fill: '#6d7893c4'
}

export const UserIcon = ({ width, height, fill, ...otherProps }) => (
  <Svg
    width={rem(width)}
    height={rem(height)}
    style={{ marginRight: rem(10) }}
    path={[
      'M12 12C15.094 12 17.625 9.46869 17.625 6.375C17.625 3.28131 15.094 0.75 12 0.75C8.90595 0.75 6.375 3.28131 6.375 6.375C6.375 9.46869 8.90595 12 12 12ZM12 14.8125C8.27355 14.8125 0.75 16.7111 0.75 20.4375V23.25H23.25V20.4375C23.25 16.7111 15.7264 14.8125 12 14.8125Z'
    ]}
    fill={fill}
    viewBox="0 0 24 24"
    {...otherProps}
  />
)

UserIcon.defaultProps = {
  width: 20,
  height: 20,
  fill: '#6d7893c4'
}

export const NextIcon = ({ width, height, fill, ...otherProps }) => (
  <Svg
    width={rem(width)}
    height={rem(height)}
    style={{ marginLeft: rem(10) }}
    path={[
      'M16.9805 0.387687C16.5322 0.82714 16.5322 1.55663 16.9717 2.00488L22.3154 7.35741H1.97754C1.35352 7.35741 0.84375 7.86718 0.84375 8.49999C0.84375 9.1328 1.35352 9.64257 1.97754 9.64257H22.3066L16.9629 14.9951C16.5234 15.4434 16.5322 16.1641 16.9717 16.6123C17.4199 17.0517 18.1318 17.0518 18.5801 16.6035L25.8223 9.30859C25.9189 9.20312 25.998 9.08886 26.0596 8.94823C26.1211 8.80761 26.1475 8.6582 26.1475 8.50878C26.1475 8.20995 26.0332 7.9287 25.8223 7.70898L18.5801 0.414055C18.1494 -0.0429767 17.4287 -0.051766 16.9805 0.387687V0.387687Z'
    ]}
    fill={fill}
    viewBox="0 0 27 17"
    {...otherProps}
  />
)

NextIcon.defaultProps = {
  width: 20,
  height: 20,
  fill: '#6d7893c4'
}

export const CoffeeIcon = ({
  width,
  height,
  fill,
  stroke,
  strokeWidth,
  ...otherProps
}) => (
  <Svg
    width={rem(width)}
    height={rem(height)}
    path={[
      'M17 8H18C19.0609 8 20.0783 8.42143 20.8284 9.17157C21.5786 9.92172 22 10.9391 22 12C22 13.0609 21.5786 14.0783 20.8284 14.8284C20.0783 15.5786 19.0609 16 18 16H17',
      'M1 8H17V17C17 18.0609 16.5786 19.0783 15.8284 19.8284C15.0783 20.5786 14.0609 21 13 21H5C3.93913 21 2.92172 20.5786 2.17157 19.8284C1.42143 19.0783 1 18.0609 1 17V8Z',
      'M5 1V4',
      'M9 1V4',
      'M13 1V4'
    ]}
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    viewBox="0 0 23 22"
    {...otherProps}
  />
)

CoffeeIcon.defaultProps = {
  width: 30,
  height: 30,
  stroke: '#0b0b0b',
  strokeWidth: 2,
  fill: 'none'
}

export const MailIcon = ({
  width,
  height,
  fill,
  stroke,
  strokeWidth,
  ...otherProps
}) => (
  <Svg
    width={rem(width)}
    height={rem(height)}
    path={[
      'M3 1H19C20.1 1 21 1.9 21 3V15C21 16.1 20.1 17 19 17H3C1.9 17 1 16.1 1 15V3C1 1.9 1.9 1 3 1Z',
      'M21 3L11 10L1 3'
    ]}
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    viewBox="0 0 22 18"
    {...otherProps}
  />
)

MailIcon.defaultProps = {
  width: 30,
  height: 30,
  stroke: '#0b0b0b',
  strokeWidth: 2,
  fill: 'none'
}

export const PageIcon = ({
  width,
  height,
  fill,
  stroke,
  strokeWidth,
  ...otherProps
}) => (
  <Svg
    width={rem(width)}
    height={rem(height)}
    path={[
      'M10 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V19C1 19.5304 1.21071 20.0391 1.58579 20.4142C1.96086 20.7893 2.46957 21 3 21H15C15.5304 21 16.0391 20.7893 16.4142 20.4142C16.7893 20.0391 17 19.5304 17 19V8L10 1Z',
      'M10 1V8H17'
    ]}
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    viewBox="0 0 18 22"
    {...otherProps}
  />
)

PageIcon.defaultProps = {
  width: 30,
  height: 30,
  stroke: '#0b0b0b',
  strokeWidth: 2,
  fill: 'none'
}

export const PieIcon = ({
  width,
  height,
  fill,
  stroke,
  strokeWidth,
  ...otherProps
}) => (
  <Svg
    width={rem(width)}
    height={rem(height)}
    path={[
      'M21.2099 15.89C20.5737 17.3945 19.5787 18.7202 18.3118 19.7513C17.0449 20.7824 15.5447 21.4874 13.9424 21.8048C12.34 22.1221 10.6843 22.0421 9.12006 21.5718C7.55578 21.1015 6.13054 20.2551 4.96893 19.1067C3.80733 17.9582 2.94473 16.5428 2.45655 14.984C1.96837 13.4251 1.86948 11.7705 2.16851 10.1646C2.46755 8.55878 3.15541 7.05063 4.17196 5.77203C5.18851 4.49343 6.5028 3.48332 7.99992 2.83',
      'M22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2V12H22Z'
    ]}
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    viewBox="0 0 24 24"
    {...otherProps}
  />
)

PieIcon.defaultProps = {
  width: 30,
  height: 30,
  stroke: '#0b0b0b',
  strokeWidth: 2,
  fill: 'none'
}

export const ChatIcon = ({
  width,
  height,
  fill,
  stroke,
  strokeWidth,
  ...otherProps
}) => (
  <Svg
    width={rem(width)}
    height={rem(height)}
    path={['M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z']}
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    viewBox="0 0 24 20"
    style={{ marginRight: rem(10) }}
    {...otherProps}
  />
)

ChatIcon.defaultProps = {
  width: 22,
  height: 22,
  fill: 'rgba(109, 120, 147, 0.77)',
  strokeWidth: 2,
  stroke: 'none'
}
