import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Home from '../components/home'

const IndexPage = () => (
  <Layout>
    <SEO
      title="Muse"
      description="Muse is a piece of software built by a Care Leaver to help improve the service the local authority provides to children in foster care. Creating more engagement and transparency."
      keywords={[
        `Muse`,
        `Local Authority`,
        `Software`,
        `Foster Care`,
        `App`,
        `Chat`
      ]}
    />
    <Home />
  </Layout>
)

export default IndexPage
