import React, { Component } from 'react'
import anime from 'animejs'
// Images
import arrow from '../images/arrow.svg'
import hero from '../images/hero.svg'
import person from '../images/person.svg'
import person2 from '../images/person-2.svg'
import person3 from '../images/person-3.svg'
import log from '../images/log.jpg'
import chat from '../images/chat.jpg'
// import payments from '../images/payments.jpg'
import caseStudy from '../images/case.svg'
import cccLogo from '../images/CCC-Logo.png'

// import {
//   IceCreamIcon,
//   CloudIcon,
//   NotifyIcon,
//   SupportIcon,
//   TeamIcon,
//   UserIcon,
//   NextIcon,
//   CoffeeIcon,
//   MailIcon,
//   PageIcon,
//   PieIcon,
//   ChatIcon
// } from './icons'
import { NextIcon, CoffeeIcon, MailIcon, PageIcon, PieIcon } from './icons'
import './home.css'

const featuresMock = [
  {
    icon: '/icons/lock.svg',
    title: '100% Secure',
    desc:
      'We take security seriously at Muse, and we exceed the industry standard when it comes to protecting your organization.'
  },
  {
    icon: '/icons/layout.svg',
    title: 'Desktop App',
    desc:
      "We've packed everything neatly into a desktop experience so that you can work with no distractions."
  },
  {
    icon: '/icons/track.svg',
    title: 'Real-time Tracking',
    desc:
      'Everything updates in real-time, no need to worry about having old information.'
  },
  {
    icon: '/icons/clock.svg',
    title: 'Timeline View',
    desc:
      'At a glance you can view every interaction your team has had with someone.'
  },
  {
    icon: '/icons/cloud.svg',
    title: 'Cloud based',
    desc: 'Everything is backed up and available instantly. '
  },
  {
    icon: '/icons/fast.svg',
    title: 'Fast',
    desc: 'We use cutting edge technology to keep things up to speed.'
  },
  {
    icon: '/icons/offline.svg',
    title: 'Offline Mode',
    desc:
      "We have built Muse in a way that doesn't slow you down when your internet cuts out."
  },
  {
    icon: '/icons/file-text.svg',
    title: 'No more paper',
    desc: 'Drag-and-drop PDFs, images and other files directly into your chat.'
  }
]

const Feature = props => (
  <div className="col-lg-3 col-md-4 feature">
    <img src={props.data.icon} className="icon" alt="" />
    <h4>{props.data.title}</h4>
    <p>{props.data.desc}</p>
  </div>
)

class Home extends Component {
  componentDidMount() {
    anime({
      targets: '#logo path',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutQuart',
      duration: 1000,
      stroke: '#0b0b0b',
      delay: 300,
      direction: 'alternate',
      loop: 2
    })

    anime({
      targets: '.Loader',
      opacity: 0,
      delay: 2200,
      complete: () => {
        document.querySelector('.Loader').classList.add('hide')
      }
    })
  }

  render() {
    return (
      <>
        <div className="Loader">
          <svg
            width="60px"
            height="60px"
            viewBox="0 0 340 276"
            className="anim"
          >
            <g
              id="Proposal"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
              strokeLinecap="round"
            >
              <g
                id="A4-Copy"
                transform="translate(-116.000000, -458.000000)"
                stroke="#fff"
                strokeWidth="19"
              >
                <g id="logo" transform="translate(125.000000, 468.000000)">
                  <path
                    d="M139.7,128.7 C120.9,164 99.1,198.5 73.3,229.2 C66.6,237.2 59.4,245 50.3,250.1 C41.2,255.1 29.7,257 20.3,252.6 C4.7,245.4 0.5,225.2 1,208 C1.7,181.9 8.4,156.4 14.6,131.1 C21.1,104.4 28.5,77.7 38.5,52.1 C45.3,34.1 57,2.1 80,0.4 C100.9,-1.1 121.5,27 132.5,41.8 C146.8,61.2 157.8,82.8 169.2,104 C194.1,149.9 219.7,196.1 255.2,234.4 C263.2,243.1 272.3,251.6 284,254.8 C290.6,256.6 297.9,256.4 304,253.3 C310.5,250 315.1,243.6 317.6,236.6 C320.6,228.3 321.1,219.1 320.8,210.4 C319.2,151.1 299.7,93.9 278.1,38.7 C271.7,22.2 262.6,3.5 242.5,2.4 C230.2,1.7 219.5,9.1 211.4,17.8 C201.1,29 193.4,42.7 183.9,54.6"
                    id="Path"
                  />
                </g>
              </g>
            </g>
          </svg>
        </div>

        <div className="Home">
          <div className="hero">
            <div className="container">
              <div className="row middle-xs">
                <div className="col-lg-7 col-md-6">
                  <h1>We are building a better future for children in care.</h1>
                  <a
                    href="https://musedotcare.typeform.com/to/JUAkzd"
                    className="btn btn-red"
                  >
                    Request Demo <img src={arrow} className="arrow" alt="" />
                  </a>
                </div>
                <div className="col-lg-5 col-md-6 align-center">
                  <img src={hero} className="hero-img" alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="points bg-light slant">
            <div className="container">
              <div className="row center-xs">
                <div className="col-lg-6 col-md-8">
                  <h2>Save Time.</h2>
                </div>
              </div>
              <div className="row center-xs">
                <div className="col-lg-4 col-md-4 person">
                  <img src={person} alt="" />
                  <h4>Be more productive</h4>
                  <p>
                    This software strips away tasks that take up most of the
                    day. Now you can focus on what really matters.
                  </p>
                </div>
                <div className="col-lg-4 col-md-4 person">
                  <img src={person2} alt="" />
                  <h4>Cut down on paperwork</h4>
                  <p>
                    Paper gets lost. We're building a digital system to archive
                    your important documents so you can find them later.
                  </p>
                </div>
                <div className="col-lg-4 col-md-4 person">
                  <img src={person3} alt="" />
                  <h4>Communication</h4>
                  <p>
                    We've built a chat app for you to communicate not only
                    quicker with your team but also with your clients.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="features container">
            <div className="row center-xs">
              <div className="col-lg-6 col-xs-10">
                <h2>Our Features</h2>
              </div>
            </div>
            <div className="row center-xs middle-xs reversed">
              <div className="col-lg-4 col-md-4 feature">
                <h3>Digital Case Notes</h3>
                <p>
                  Having to record everything can be a pain, which is why Muse
                  allows you to digitally log each interaction you have with
                  your case.
                </p>
              </div>
              <div className="col-lg-5 col-md-5 col-md-offset-1 feature">
                <img src={log} width="100%" alt="" />
              </div>
            </div>
            <div className="row center-xs middle-xs">
              <div className="col-lg-5 col-md-5 feature">
                <img src={chat} width="100%" alt="" />
              </div>
              <div className="col-lg-4 col-md-4 col-md-offset-1 feature">
                <h3>Secure Chat</h3>
                <p>
                  No need to manually input sms conversations into a log. We
                  built a way for you to chat with the people you care about.
                </p>
              </div>
            </div>
            {/* <div className="row center-xs middle-xs reversed">
              <div className="col-lg-4 col-md-4 feature">
                <h3>Payments</h3>
                <p>
              Are coming soon. We are working on a product to help you keep
              track of where the money is going, this will be a great help
              when it comes to auditing.
                </p>
              </div>
              <div className="col-lg-5 col-md-5 col-md-offset-1 feature">
                <img src={payments} width="100%" alt="" />
              </div>
            </div> */}
          </div>

          <div className="container space">
            <div className="row center-xs">
              <div className="col-lg-6 col-md-8">
                <h2>There's More</h2>
              </div>
            </div>
            <div className="row center-xs">
              <div className="col-lg-10 col-md-10">
                <div className="row more-features">
                  {featuresMock.map((item, key) => {
                    return <Feature data={item} key={key} />
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className="case bg-light space">
            <div className="container">
              <div className="row center-xs middle-xs">
                <div className="col-lg-6">
                  <img src={caseStudy} width="50%" alt="" />
                </div>
                <div className="col-lg-6 text-left mobile-center">
                  <h2>Case Study</h2>
                  <img src={cccLogo} width="200px" alt="" />
                  <p>
                    We are currently working with Carmarthenshire County Council
                    to help their Next Step Team work more efficiently to help
                    the young people they care for. Below are a few projected
                    milestones we are aiming for.
                  </p>
                  <div className="row milestones">
                    <div className="col-lg-6 col-xs-12 feature">
                      <PieIcon stroke={'#175cf8'} />
                      <h4>75% More Engagement</h4>
                    </div>
                    <div className="col-lg-6 col-xs-12 feature">
                      <MailIcon stroke={'#175cf8'} />
                      <h4>45% Less Email</h4>
                    </div>
                    <div className="col-lg-6 col-xs-12 feature">
                      <CoffeeIcon stroke={'#175cf8'} />
                      <h4>36hrs / week saved</h4>
                    </div>
                    <div className="col-lg-6 col-xs-12 feature">
                      <PageIcon stroke={'#175cf8'} />
                      <h4>Reduce Paper</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="pricing container space">
            <div className="row center-xs">
              <div className="col-lg-6">
            <h2 className="title">Pricing</h2>
            <p className="subtitle-dark">
            No matter if your team is big or small we have something for
            you. We also offer a service to customise our platform to fit
            your needs.
            </p>
              </div>
            </div>
            <div className="row center-xs">
              <div className="col-lg-4 col-md-4 pricing-block">
            <div className="pricing-content">
            <h4 className="text-light">Basic</h4>
            <h2>£850</h2>
            <h4>per month</h4>
            <span className="text-light">Billed Annually</span>
            <ul className="pricing-items">
            <li>
            <UserIcon /> 10 users
            </li>
            <li>
            <TeamIcon /> 1 Team
            </li>
            <li>
            <ChatIcon /> Secure Chat
            </li>
            <li>
            <SupportIcon /> Basic Support
            </li>
            <li>
            <NotifyIcon /> Notifications
            </li>
            <li>
            <CloudIcon /> Cloud Backups
            </li>
            <li>
            <IceCreamIcon /> Free Ice Cream
            </li>
            </ul>
            <a
            href="https://musedotcare.typeform.com/to/VOUPM7"
            className="btn btn-full text-center"
            >
            Contact Sales
            </a>
            </div>
              </div>
              <div className="col-lg-4 col-md-4 pricing-block">
            <div className="pricing-content">
            <h4 className="text-light">Standard</h4>
            <h2>£2000</h2>
            <h4>per month</h4>
            <span className="text-light">Billed Annually</span>
            <ul className="pricing-items">
            <li>
            <UserIcon /> 50 users
            </li>
            <li>
            <TeamIcon /> 5 Teams
            </li>
            <li>
            <ChatIcon /> Secure Chat
            </li>
            <li>
            <SupportIcon /> Premium Support
            </li>
            <li>
            <NotifyIcon /> Notifications
            </li>
            <li>
            <CloudIcon /> Cloud Backups
            </li>
            <li>
            <IceCreamIcon /> Free Ice Cream
            </li>
            </ul>
            <a
            href="https://musedotcare.typeform.com/to/hwDe76"
            className="btn btn-full text-center"
            >
            Contact Sales
            </a>
            </div>
              </div>
            </div>
          </div> */}

          <div className="contact bg-dark space">
            <div className="row center-xs">
              <div className="col-lg-6 col-xs-10">
                <h2 className="title">Still Have Questions?</h2>
                <p className="subtitle">
                  That's fine we are here to answer them.
                </p>
                <div className="col-lg-4 align-center">
                  <a
                    href="https://musedotcare.typeform.com/to/JUAkzd"
                    className="btn btn-light center"
                  >
                    Contact Us <NextIcon fill={'#0b0b0b'} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Home
