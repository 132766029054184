import { Link } from 'react-scroll'
import React from 'react'

import logo from '../images/Logo.svg'
import './header.css'

export default class Header extends React.Component {
  state = {
    navOpen: false
  }

  onExpand = () => {
    this.setState({ navOpen: !this.state.navOpen })
  }

  render() {
    const { navOpen } = this.state

    const navButton = ['nav-button']
    const navMenu = ['menu-items']

    if (navOpen) {
      navMenu.push('menu-open')
      navButton.push('open')
    }

    return (
      <div className="container">
        <div className="nav">
          <img src={logo} className="Logo" alt="" />
          <div className={navMenu.join(' ')}>
            <Link
              className="menu-link"
              to="features"
              spy
              smooth="easeInOutQuart"
              duration={1000}
              onClick={this.onExpand}
            >
              Features
            </Link>
            <Link
              className="menu-link"
              to="case"
              spy
              smooth="easeInOutQuart"
              duration={1000}
              onClick={this.onExpand}
            >
              Case Study
            </Link>
            {/* <Link
              className="menu-link"
              to="pricing"
              spy
              smooth="easeInOutQuart"
              duration={1000}
              onClick={this.onExpand}
              >
              Pricing
            </Link> */}
            <Link
              className="menu-link"
              to="contact"
              spy
              smooth="easeInOutQuart"
              duration={1000}
              onClick={this.onExpand}
            >
              Contact
            </Link>
          </div>
        </div>
        {/* Mobile Menu */}
        <div
          className={navButton.join(' ')}
          id="mobile"
          onClick={this.onExpand}
        >
          <span className="top" />
          <span className="bottom" />
        </div>
      </div>
    )
  }
}
