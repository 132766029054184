import React from 'react'

/* Svg */
export const Svg = ({ name, width, height, path, fill, ...otherProps }) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    {path.map((d, index) => (
      <path key={`icon-path-${index}`} fill={fill} d={d} />
    ))}
  </svg>
)
