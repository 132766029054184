import React from 'react'
import logo2 from '../images/Logo-2.svg'
import './footer.css'

const Footer = () => (
  <div className="footer bg-dark">
    <div className="container">
      <div className="row middle-xs">
        <div className="col-lg-4 col-md-12 col-xs-12 left">
          <img className="footer-logo" src={logo2} width="45px" alt="" />
        </div>
        <div className="col-lg-4 col-md-12 col-xs-12 text-center">
          <a href="mailto:sales@muse.care" title="Email us">
            Company number 11582749
          </a>
        </div>
        <div className="col-lg-4 col-md-12 col-xs-12 right">
          <h4>
            Made with{' '}
            <span role="img" aria-label="heart">
              ❤️
            </span>{' '}
          </h4>
        </div>
      </div>
    </div>
  </div>
)

export default Footer
